import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import DrawerProfile from '../drawer/DrawerProfile';
import MsgBox from '../lib/MsgBox';

const Header = (props) => {
    const [subProduct, setSubProduct] = React.useState();
    const openSubProduct = Boolean(subProduct);
    const [subServices, setSubServices] = React.useState();
    const openSubServices = Boolean(subServices);
    const [subBilling, setSubBilling] = React.useState();
    const openSubBilling = Boolean(subBilling);

    const [cookie,setCookie] = useCookies(['bas_']);
    const [isMsgBox, setisMsgBox] = React.useState({})
    const [isSalesOpen, setIsSalesOpen] = useState(false)
    const ProfileRef = useRef();

    const handleClickSubProduct = (event) => {
        setSubProduct(event.currentTarget);
    };
    const handleCloseSubProduct = () => {
        setSubProduct(null);
    };

    const handleClickSubServices = (event) => {
        setSubServices(event.currentTarget);
    };
    const handleCloseSubServices = () => {
        setSubServices(null);
    };

    const handleClickSubBilling = (event) => {
        setSubBilling(event.currentTarget);
    };
    const handleCloseSubBilling = () => {
        setSubBilling(null);
    };


    const [anchorEl, setAnchorEl] = React.useState();
    const open = Boolean(anchorEl);

    const afterSave =()=>{

    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const afterUpdate=()=>{
        setisMsgBox({
          open: true,
          status: 'success',
          title:'Update Profile',
          msg: 'Update Profile successfully'
        })
      }
    
    React.useEffect(() => {
        if(cookie.profile){
            ProfileRef.current.openForm(props.config);
            setCookie('profile',false)
            afterUpdate()
        }
      }, []);

  return (
    <div>
        <MsgBox config={isMsgBox} />
        <DrawerProfile ref={ProfileRef} config={props.config} refreshProductSave={afterSave} />
        <Mui.AppBar position="fixed">
            <Mui.Toolbar disableGutters>
                <MuiIcon.Route sx={{ m: 2 }} style={{fontSize:'36'}} />
                <Mui.Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/dashboard"
                    sx={{
                        mr: 2,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    {props.config.appName} 
                </Mui.Typography>
                <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                <Mui.Button variant='text' color='inherit' sx={{m:1}} href='/dashboard'>Project Monitoring</Mui.Button>
                <Mui.Button variant='text' color='inherit' sx={{m:1}} href='/vendors'>Vendors</Mui.Button>

                <Mui.Button color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} onClick={handleClick}><MuiIcon.Settings fontSize="small" /></Mui.Button>

                <Mui.Button color='inherit' sx={{minHeight: 0, minWidth: 0, padding: 1}} 
                onClick={()=> {
                    ProfileRef.current.openForm(props.config);
                }}><MuiIcon.AccountCircle fontSize="small" /></Mui.Button>
                <Mui.Button color='inherit' href='/signout' sx={{minHeight: 0, minWidth: 0, padding: 1}}><MuiIcon.Logout fontSize="small" /></Mui.Button>
                
                
                <Mui.Menu
                    anchorEl={anchorEl}
                    id="sub-data"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {cookie.job_id < 2 ? (
                    <Mui.Link href="/users" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.Group fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Users</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>):('')}
                    {cookie.job_id < 1 ? (
                    <Mui.Link href="/taxo" style={{textDecoration:'none', color:'#000000'}}>
                        <Mui.MenuItem>
                            <Mui.ListItemIcon>
                                <MuiIcon.TravelExplore fontSize="small" />
                            </Mui.ListItemIcon>
                            <Mui.ListItemText>Taxonomy</Mui.ListItemText>
                        </Mui.MenuItem>
                    </Mui.Link>):('')}
                </Mui.Menu>
            </Mui.Toolbar>
        </Mui.AppBar>
    </div>
  );
}

export default Header;
